/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1.5;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* end eric meyer's reset */
.clear:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clear {
  display: inline-block;
}
/* hide IE mac \*/
* html .clear {
  height: 1%;
}
.clear {
  display: block;
}
/* */
* {
  box-sizing: border-box;
}
html,
body {
  font-size: 62.5%;
  height: 100%;
}
body {
  font-size: 1.6rem;
  font-family: 'Open Sans', Arial, sans-serif;
  position: relative;
  color: white;
  overflow-x: hidden;
  background-color: #10224c;
}
a {
  color: #f5c200;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a:hover {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', Arial, sans-serif;
  color: #dddec7;
  padding: 0px 15px;
}
h1 {
  font-family: 'Changa', Arial, sans-serif;
}
@-webkit-keyframes typing {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
@-webkit-keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #dddec7;
  }
}
@-moz-keyframes typing {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
@-moz-keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #dddec7;
  }
}
@keyframes typing {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #dddec7;
  }
}
h1 {
  font-size: 30px;
  text-align: center;
  max-width: 600px;
  margin: 0px auto 25px auto;
  font-weight: 400;
  text-shadow: black 2px 2px 2px;
  padding-top: 30px;
  position: relative;
}
h1 strong {
  color: #f5c200;
}
@media screen and (min-width: 830px) {
  h1 em {
    display: block;
  }
}
@media screen and (min-width: 480px) {
  h1 {
    font-size: 50px;
  }
}
@media screen and (min-width: 960px) {
  h1 span {
    position: absolute;
    top: 30px;
    width: 0;
    right: 10px;
    background: #10224c;
    /* same as background */
    border-left: .1em solid #dddec7;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    animation: typing 7s steps(30, end), blink-caret 1s step-end infinite;
    -webkit-animation: typing 7s steps(30, end), blink-caret 1s step-end infinite;
    -moz-animation: typing 7s steps(30, end), blink-caret 1s step-end infinite;
  }
}
h2,
dt {
  font-family: 'Open Sans', Arial, sans-serif;
  color: #f5c22d;
  padding: 0px 15px;
  font-weight: 400;
  max-width: 830px;
  margin: 0px auto 0px auto;
  text-align: center;
  text-shadow: black 2px 2px 2px;
}
@media screen and (min-width: 480px) {
  h2,
  dt {
    font-size: 50px;
  }
}
h2,
dt:last-of-type {
  font-size: 30px;
}
h4 {
  font-size: 20px;
  line-height: 35px;
  padding: 0px 15px;
  font-weight: 400;
  font-size: 30px;
  max-width: 830px;
  margin: 20px auto 20px auto;
  text-align: center;
  /*text-shadow: black 2px 2px 2px;*/
}
@media screen and (min-width: 480px) {
  h4 {
    font-size: 23px;
  }
}
p {
  max-width: 900px;
  margin: 0px auto 0px auto;
  text-align: center;
  /*text-shadow: #000000 2px 2px 5px;*/
  font-size: 17px;
  padding: 0 15px;
}
p:first-of-type {
  max-width: 830px;
  font-size: 17px;
  margin-top: 20px;
}
@media screen and (min-width: 640px) {
  p:first-of-type {
    font-size: 21px;
  }
}
#bg_pattern {
  background-image: url(/images/bg_pattern.png);
}
img {
  height: auto;
  max-width: 100%;
}
hr {
  border-top: 1px solid #152853;
  border-left: 0px none transparent;
  border-right: 0px none transparent;
  border-bottom: 0px none transparent;
  margin: 40px auto 0px auto;
  max-width: 200px;
}
.main-content {
  background-image: url(/images/background.jpg);
  background-attachment: fixed;
  background-color: #10224c;
  background-size: cover;
}
b,
strong {
  font-weight: 600 !important;
}
dl:last-of-type {
  text-align: center;
  max-width: 100%;
}
dl:last-of-type dt {
  background: #051435;
  color: rgba(255, 255, 255, 0.5);
  padding: 20px 20px 40px 20px;
}
dl:last-of-type img {
  max-width: 350px;
  width: 100%;
  display: block;
  margin: 5px auto 0 auto;
}
dl:last-of-type em {
  padding: 40px 40px 0px 40px;
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
}
@media screen and (min-width: 768px) {
  dl:last-of-type {
    max-width: 100%;
    font-size: 0px;
  }
  dl:last-of-type dt {
    font-size: 15px;
    display: inline-block;
    width: 35%;
    height: 160px;
    padding: 40px 40px 0px 40px;
    vertical-align: top;
  }
  dl:last-of-type dd:first-of-type {
    font-size: 16px;
    display: inline-block;
    width: 65%;
    height: 160px;
    background: #051435;
    vertical-align: top;
  }
}
dl:last-of-type dd:last-of-type {
  padding: 20px 0px 20px 0px;
  background-color: #051435;
}
dl:last-of-type dd:last-of-type p {
  margin: 15px auto 0px auto;
  font-size: 16px;
  color: #77839E;
}
dl:last-of-type dd:last-of-type p strong {
  font-weight: bold;
}
dl:last-of-type dd:last-of-type p a {
  color: white;
  font-weight: 400;
  text-decoration: none;
}
dl:last-of-type dd:last-of-type p a:hover {
  text-decoration: underline;
}
.overlay .modal h2 {
  font-family: 'Open Sans', Arial, sans-serif;
}
.explainer,
.explainer-sub,
.explainer-last {
  padding-bottom: 30px;
  padding-top: 20px;
  margin-bottom: -1px;
}
.explainer {
  background-color: #10224c;
}
.explainer-last {
  background: #10224c;
}
.explainer-sub {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(16, 34, 76, 0.99) 99%, #10224c 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(16, 34, 76, 0.99) 99%, #10224c 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(16, 34, 76, 0.99) 99%, #10224c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#10224c', GradientType=0);
}
.explainer-sub a {
  color: #fff;
  border-bottom: 2px solid #f5c200;
}
.explainer-sub a:hover {
  color: #f5c200;
}
.button,
.share,
.tweet,
.donate {
  display: inline-block;
  border-radius: 5px;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  width: 126px;
  height: 36px;
  line-height: 36px;
  margin: 5px 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 10px 10px 10px 10px;
}
.button.share,
.share.share,
.tweet.share,
.donate.share,
.button.tweet,
.share.tweet,
.tweet.tweet,
.donate.tweet,
.button.donate,
.share.donate,
.tweet.donate,
.donate.donate {
  padding-left: 25px;
}
.button.tweet,
.share.tweet,
.tweet.tweet,
.donate.tweet {
  background: #55acee url(../images/tweet.png) 8px 10px no-repeat;
  background-size: 22px auto;
}
.button.share,
.share.share,
.tweet.share,
.donate.share {
  background: #3b579d url(../images/facebook.png) 5px 6px no-repeat;
  background-size: 22px auto;
}
.button.donate,
.share.donate,
.tweet.donate,
.donate.donate {
  background: #fc3660 url(../images/donate.png) 10px 9px no-repeat;
}
.button[href="#twitter"],
.share[href="#twitter"],
.tweet[href="#twitter"],
.donate[href="#twitter"],
.button[href="#sites"],
.share[href="#sites"],
.tweet[href="#sites"],
.donate[href="#sites"] {
  font-size: 17px;
  height: 48px;
  line-height: 48px;
  padding: 0px 25px;
  width: 228px;
  margin: 0px 15px 25px 15px;
}
.button[href="#twitter"],
.share[href="#twitter"],
.tweet[href="#twitter"],
.donate[href="#twitter"] {
  background: #55acee;
}
.button[href="#sites"],
.share[href="#sites"],
.tweet[href="#sites"],
.donate[href="#sites"] {
  display: none;
}
@media screen and (min-width: 480px) {
  .button[href="#sites"],
  .share[href="#sites"],
  .tweet[href="#sites"],
  .donate[href="#sites"] {
    display: inline-block;
    background: #EFC32C;
  }
}
.share:hover,
.tweet:hover,
.donate:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.2);
}
.star {
  position: absolute;
  animation: fade-out 1200ms linear infinite;
  width: 20px;
  margin-top: -11px;
  margin-left: -10px;
  opacity: 0;
}
.star01 {
  left: 50%;
  top: 0%;
  animation-delay: 0ms;
}
.star02 {
  left: 77%;
  top: 8%;
  animation-delay: 100ms;
}
.star03 {
  left: 93%;
  top: 25%;
  animation-delay: 200ms;
}
.star04 {
  left: 100%;
  top: 50%;
  animation-delay: 300ms;
}
.star05 {
  left: 93%;
  top: 74%;
  animation-delay: 400ms;
}
.star06 {
  left: 77%;
  top: 92%;
  animation-delay: 500ms;
}
.star07 {
  left: 50%;
  top: 100%;
  animation-delay: 600ms;
}
.star08 {
  left: 23%;
  top: 92%;
  animation-delay: 700ms;
}
.star09 {
  left: 7%;
  top: 74%;
  animation-delay: 800ms;
}
.star10 {
  left: 0%;
  top: 50%;
  animation-delay: 900ms;
}
.star11 {
  left: 7%;
  top: 25%;
  animation-delay: 1000ms;
}
.star12 {
  left: 23%;
  top: 8%;
  animation-delay: 1100ms;
}
.eu-spin {
  position: absolute;
  left: 50%;
  top: 45px;
  margin-left: -55px;
  width: 110px;
  height: 110px;
  padding: 15px;
}
.eu-spin > div {
  width: 100%;
  height: 100%;
  -webkit-animation: spin 60s linear infinite;
  -moz-animation: spin 60s linear infinite;
  animation: spin 60s linear infinite;
}
.eu-spin > div div {
  display: inline-block;
  width: 50%;
  height: 50%;
}
.eu-spin > div div:first-child {
  border-right: 1px solid #eabf0d;
}
@keyframes fade-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.copy {
  margin: 0 auto;
  max-width: 72ch;
}
.inline {
  display: inline;
  margin: 0;
  padding: 0;
}
.inline li {
  display: inline;
}
ul,
ol {
  list-style: none;
  margin: 0px auto 0px auto;
  text-align: center;
  text-shadow: #3e3e3e 2px 2px 2px;
  font-size: 21px;
  padding: 15px;
  font-weight: 400;
}
ul.piped li,
ol.piped li {
  margin: 0;
  padding: 0;
}
ul.piped li::before,
ol.piped li::before {
  content: '|\00a0';
  display: inline-block;
}
ul.piped li:first-child::before,
ol.piped li:first-child::before {
  content: '';
  display: none;
}
/************************************************
*   Fixed Social Container          * 
************************************************/
#fixed-side-social-container {
  display: none;
  left: 0;
  width: 50px;
  position: fixed;
  top: 397px;
  background-repeat: no-repeat;
  z-index: 10;
  padding: 0 5px;
}
#fixed-side-social-container button {
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
}
#fixed-side-social-container .social-icon {
  float: left;
  width: 50px;
  filter: alpha(opacity=75);
  moz-opacity: .75;
  -khtml-opacity: .75;
  opacity: .75;
  display: block;
  background-repeat: no-repeat;
  height: 49px;
  -webkit-transition: all ease .2s;
  -moz-transition: all ease .2s;
  -o-transition: all ease .2s;
  -ms-transition: all ease .2s;
  transition: all ease .2s;
  background-position: 0 0;
}
#fixed-side-social-container .social-icon {
  background-image: url("/images/social-media.png");
  margin-bottom: 10px;
  background-size: 100%;
}
#fixed-side-social-container .social-icon:hover {
  filter: alpha(opacity=99);
  moz-opacity: .99;
  -khtml-opacity: .99;
  opacity: .99;
}
#fixed-side-social-container .instagram-icon {
  background-position: 0 0;
}
#fixed-side-social-container .twitter-icon {
  background-position: 0 -51px;
}
#fixed-side-social-container .facebook-icon {
  background-position: 0 -102px;
}
#fixed-side-social-container .google-icon {
  background-position: 0 -153px;
}
.overlay .gutter .modal._donate_modal {
  padding: 0px 0px 20px 0px;
  max-width: 494px;
  width: 100%;
}
.overlay .gutter .modal._donate_modal h2 {
  width: 100%;
  background: #e6edf2;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 40px 10px 40px 10px;
  font-weight: 300;
  font-size: 68px;
  color: #3a5474;
  margin-bottom: 0px;
}
.overlay .gutter .modal._donate_modal h3 {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 30px;
  color: black;
  font-weight: 400;
  margin-bottom: 10px;
}
.overlay .gutter .modal._donate_modal hr {
  display: block;
  margin-top: -23px;
  border: 0px;
  width: 42px;
  height: 42px;
  background: url(../images/heart.png);
  background-size: 100% 100%;
}
.overlay .gutter .modal._donate_modal p {
  width: 100%;
  padding: 0px 20px;
  font-size: 16px;
  line-height: 23px;
  color: #232c37;
  margin-bottom: 20px;
}
.overlay .gutter .modal._donate_modal button.donate {
  width: 300px;
  padding: 10px 0px 10px 0px;
  background: #22cfa8;
  border: 5px solid #d5eee8;
  font-size: 33px;
  letter-spacing: 3px;
  color: white;
  border-radius: 100px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
}
.overlay .gutter .modal._donate_modal button.donate:hover {
  background: #12bf98;
}
.overlay .gutter .modal._donate_modal strong {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #5b6470;
}
.overlay .gutter .modal._donate_modal a {
  color: #1db28d;
  font-size: 14px;
  font-weight: normal;
}
dl:first-of-type {
  display: block;
  margin: 30px auto 30px auto;
  max-width: 830px;
  background: rgba(16, 34, 76, 0.73);
  color: white;
  overflow: hidden;
  transition: opacity .4s linear;
}
dl:first-of-type.invisible {
  height: 0px;
}
dl:first-of-type em {
  display: none;
}
dl:first-of-type label {
  display: none;
}
dl:first-of-type dt {
  padding: 15px;
  font-size: 17px;
  text-align: center;
  font-weight: 400;
}
@media screen and (min-width: 480px) {
  dl:first-of-type dt {
    padding: 15px 20px;
    font-size: 24px;
  }
}
dl:first-of-type dd {
  padding: 15px 30px 15px 30px;
}
@media screen and (min-width: 480px) {
  dl:first-of-type dd {
    padding: 15px 30px 15px 30px;
  }
}
dl:first-of-type #petition-form input[type="submit"]:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.2);
}
dl:first-of-type #petition-form input[type="submit"] {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
dl:first-of-type #petition-form {
  position: relative;
}
dl:first-of-type #petition-form input,
dl:first-of-type #petition-form select {
  display: inline-block;
  margin-bottom: 15px;
  padding: 0px 15px;
  font-weight: 400;
  width: 100%;
  height: 55px;
  line-height: 55px;
  border: 1px solid #798db6;
  border-radius: 5px;
  background: white;
  color: black;
  font-size: 20px;
  font-family: 'Open Sans';
}
dl:first-of-type #petition-form input[id="select-country"],
dl:first-of-type #petition-form select[id="select-country"] {
  display: none;
}
dl:first-of-type #petition-form input[type="submit"],
dl:first-of-type #petition-form select[type="submit"] {
  background: #ae1802;
  border: 0px none;
  color: white;
  height: 72px;
  line-height: 72px;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
}
@media screen and (min-width: 480px) {
  dl:first-of-type #petition-form input[type="submit"],
  dl:first-of-type #petition-form select[type="submit"] {
    font-size: 25px;
  }
}
dl:first-of-type #petition-form input[type="submit"]:disabled,
dl:first-of-type #petition-form select[type="submit"]:disabled {
  cursor: default;
  background: #bbb;
}
@media screen and (min-width: 768px) {
  dl:first-of-type #petition-form input,
  dl:first-of-type #petition-form select {
    width: 270px;
  }
  dl:first-of-type #petition-form input[name="signature[street_address]"],
  dl:first-of-type #petition-form select[name="signature[street_address]"],
  dl:first-of-type #petition-form input[name="signature[zip_code]"],
  dl:first-of-type #petition-form select[name="signature[zip_code]"] {
    display: inline-block;
  }
  dl:first-of-type #petition-form input[name="signature[street_address]"],
  dl:first-of-type #petition-form select[name="signature[street_address]"] {
    width: 170px;
  }
  dl:first-of-type #petition-form input[name="signature[zip_code]"],
  dl:first-of-type #petition-form select[name="signature[zip_code]"] {
    width: 213px;
    margin-left: 6px;
  }
}
dl:first-of-type #petition-form select {
  padding: 0px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  outline: none;
  cursor: pointer;
  background-color: white;
  background: url(../images/arrow.png) right center no-repeat;
  background-size: 30px 9px;
}
dl:first-of-type #petition-form textarea {
  width: 100%;
  border: 1px solid #798db6;
  border-radius: 5px;
  background: white;
  color: black;
  padding: 10px;
  font-weight: 400;
  font-family: 'Open Sans';
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  dl:first-of-type #petition-form textarea {
    position: absolute;
    right: 0px;
    top: -1px;
    width: 369px;
    height: 194px;
    margin-bottom: 0px;
  }
}
span.disclosure {
  display: block;
  text-align: center;
}
span.disclosure p {
  display: block;
  font-size: 90%;
  max-width: 600px;
  margin: 0px auto 0px auto;
}
span.disclosure p:last-child {
  display: inline-block;
}
#confirm-modal {
  display: none;
  opacity: 0;
  margin: 30px auto;
  max-width: 830px;
  background: rgba(16, 34, 76, 0.73);
  color: #ffffff;
  overflow: hidden;
  transition: height 1s linear;
  padding: 15px;
  font-size: 17px;
  text-align: center;
  font-weight: 700;
  transition: opacity .4s linear;
}
#confirm-modal ul {
  text-align: left;
  margin: 10px 10px 0px;
  font-size: 19px;
  line-height: 25px;
}
#confirm-modal img {
  max-width: 150px;
  max-height: 150px;
  display: block;
  margin: 15px auto 30px auto;
}
@media screen and (min-width: 480px) {
  #confirm-modal {
    padding: 20px 30px;
    font-size: 20px;
  }
}
dl.widgets {
  text-align: center;
  max-width: 1100px;
  margin: 0px auto;
}
dl.widgets dt {
  max-width: 830px;
  margin: 0px auto 30px auto;
  text-align: center;
}
dl.widgets dd {
  display: none;
  width: 100%;
  max-width: 450px;
}
dl.widgets dd p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}
dl.widgets dd iframe {
  margin-top: 5px;
  width: 100%;
  max-width: 430px;
  height: 127px;
}
dl.widgets dd:last-of-type {
  padding-top: 40px;
  max-width: 550px;
}
dl.widgets dd:last-of-type iframe {
  max-width: 550px;
  height: 400px;
}
@media screen and (min-width: 480px) {
  dl.widgets dd {
    display: inline-block;
  }
}
dl.widgets code {
  display: block;
  width: 100%;
  max-width: 680px;
  overflow: hidden;
  background: #664466;
  font-family: 'Courier New', mono;
  border-radius: 5px;
  padding: 5px;
  margin: 25px auto 0px auto;
}
#clockdiv {
  color: #fff;
  display: block;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
  margin: 0px auto;
}
#clockdiv div {
  padding: 10px;
  border-radius: 3px;
  background: rgba(16, 34, 76, 0.8);
  display: inline-block;
}
#clockdiv div span {
  padding: 15px;
  border-radius: 3px;
  background: rgba(16, 34, 76, 0.73);
  display: inline-block;
}
#clockdiv div.smalltext {
  background: transparent;
  display: block;
  padding-top: 5px;
  font-size: 16px;
}
.video {
  max-width: 666px;
  margin: 0px auto;
}
.video .wrapper {
  border-radius: 1rem;
  height: 0;
  margin-bottom: 2rem;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 3rem;
  position: relative;
}
.video .wrapper iframe,
.video .wrapper object,
.video .wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
